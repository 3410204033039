<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-btn small depressed @click="$router.go(-1)"
          ><v-icon left>mdi-arrow-left</v-icon>Back</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="2" class="pr-12">
          <h1>Repair</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Repair Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.repairDialog.open(repair)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Works</th>
                  <td>{{ repair.short_description }}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{{ repair.long_description }}</td>
                </tr>
                <tr>
                  <th>Contractor</th>
                  <td>{{ repair.contractor }}</td>
                </tr>
                <tr>
                  <th>Quote</th>
                  <td>{{ repair.quote }}</td>
                </tr>
                <tr>
                  <th>Share</th>
                  <td>{{ repair.share }}</td>
                </tr>
                <tr>
                  <th>Assignee</th>
                  <td>{{ repair.assigne_user.full_name }}</td>
                </tr>
                <tr>
                  <th>Due Date</th>
                  <td>{{ formatDate(repair.due_date) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Other Properties</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.additionDialog.open(repair)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableAdditionalHeaders"
              :items="repair.additional"
              no-data-text="No Properties"
              :items-per-page="-1"
            >
              <template v-slot:item.complete="{ item }">
                <v-icon v-if="item.completed" color="green"
                  >mdi-check-circle
                </v-icon>
                <v-icon v-else color="red">mdi-close-circle </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="blue lighten-4 blue--text"
                  @click="$refs.additionDialog.open(repair, item)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="repair.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Notes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(repair)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="repair.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <NoteDialog ref="noteDialog" type="repair" />
      <RepairDialog ref="repairDialog" />
      <AdditionDialog ref="additionDialog" />
    </v-container>
  </div>
</template>

<script>
import NoteDialog from "../notes/NoteDialog.vue";
import RepairDialog from "./components/RepairDialog.vue";
import AdditionDialog from "./components/AdditionDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: { NoteDialog, RepairDialog, AdditionDialog },

  data() {
    return {
      pdfLoading: false,
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      fields: {
        attending_viewing: false,
      },
      activeHover: false,

      tableAdditionalHeaders: [
        { text: "Property", value: "text" },
        { text: "Completed", value: "complete" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      breadcrumbs: [
        {
          text: "Repairs",
          disabled: false,
          to: { name: "module-sbpm-communalrepairs" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    repair() {
      return this.$store.getters["sbpm/repairsStore/get"];
    },
  },
};
</script>
